/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as ProductCardContainerBase
} from 'SourceComponent/ProductCard/ProductCard.container';
import media, { PRODUCT_MEDIA } from 'Util/Media';

/** @namespace Viallidesign/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends ProductCardContainerBase {
    static propTypes = {
        ...ProductCardContainerBase.propTypes,
        showLookBookImage: PropTypes.bool
    };

    static defaultProps = {
        ...ProductCardContainerBase.defaultProps,
        showLookBookImage: false
    };

    _getThumbnail() {
        const product = this._getProductOrVariant();
        const {
            small_image: { url } = {},
            attributes: { lookbook_image: { attribute_value } = {} } = {}
        } = product;

        const { showLookBookImage } = this.props;

        /*
         * Lookbook image load
         * Only show on widgets, blog products, product products
         */

        if (showLookBookImage && attribute_value && this._isThumbnailAvailable(attribute_value)) {
            return media(attribute_value, PRODUCT_MEDIA);
        }

        if (this._isThumbnailAvailable(url)) {
            return url;
        }

        // If thumbnail is, missing we try to get image from parent
        const { product: { small_image: { url: parentUrl } = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }

        return '';
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
