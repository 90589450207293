/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FieldSelectContainerBase from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace Viallidesign/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends FieldSelectContainerBase {
    sortSelectOptions() {
        const {
            selectOptions,
            id
        } = this.props;

        if (id && id === 'capacity-select') {
            return selectOptions;
        }

        /**
         * Trim all null label values, sort alphabetically
         */
        const sortedOptions = selectOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        )
            .sort((a, b) => {
                const textA = a.label.toUpperCase();
                const textB = b.label.toUpperCase();
                // eslint-disable-next-line no-nested-ternary
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

        return sortedOptions;
    }
}

export default FieldSelectContainer;
