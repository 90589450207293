/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import BlogPostsQuery from '../../query/BlogPosts.query';
import BlogRecentPosts from './BlogRecentPosts.component';

/** @namespace Blog/Component/BlogRecentPosts/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

/** @namespace Blog/Component/BlogRecentPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Blog/Component/BlogRecentPosts/Container/BlogRecentPostsContainer */
export class BlogRecentPostsContainer extends PureComponent {
    __construct(props) {
        super.__construct(props);
        this.options = {
            recentPosts: {
                sort: 'DESC',
                getMedia: true,
                getDescription: true,
                allPosts: true
            }
        };

        this.state = {
            isLoaded: false,
            posts: []
        };
    }

    static propTypes = {
        numberOfPosts: PropTypes.number,
        tagId: PropTypes.number,
        authorId: PropTypes.number,
        categoryId: PropTypes.number,
        storeId: PropTypes.number
    };

    static defaultProps = {
        numberOfPosts: 3,
        tagId: 0,
        authorId: 0,
        categoryId: 0,
        storeId: 0
    };

    componentDidMount() {
        this._isMounted = true;
        this.requestPosts();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Prepares the blog posts query with specified options
     * @returns {[[*]]}
     */
    getQuery() {
        let { recentPosts } = this.options;

        const {
            numberOfPosts,
            tagId,
            authorId,
            categoryId,
            storeId
        } = this.props;

        const filter = {};

        if (tagId) {
            filter.tag_id = {
                eq: tagId
            };
        }

        if (authorId) {
            filter.author_id = {
                eq: authorId
            };
        }

        if (categoryId) {
            filter.category_id = {
                eq: categoryId
            };
        }

        // if (storeId) {
        //     filter.store_id = {
        //         eq: storeId
        //     };
        // }

        recentPosts = {
            ...recentPosts,
            pageSize: numberOfPosts,
            filter
        };

        return [BlogPostsQuery.getQuery(recentPosts)];
    }

    requestPosts() {
        executeGet(prepareQuery(this.getQuery()), 'RecentPosts')
            .then(
                /** @namespace Blog/Component/BlogRecentPosts/Container/executeGet/then */
                ({ posts: { items } }) => this.updatePostList(items, true)
            )
            .catch(
                /** @namespace Blog/Component/BlogRecentPosts/Container/executeGet/then/catch */
                (err) => {
                    this.updatePostList([], false);
                    showNotification('error', err);
                }
            );
    }

    /**
     * Maps posts to state and sets isLoaded status
     * @param posts
     * @param isLoaded
     */
    updatePostList(posts, isLoaded) {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            isLoaded,
            posts
        });
    }

    render() {
        const {
            posts,
            isLoaded
        } = this.state;

        const {
            title,
            extraClass
        } = this.props;

        return (
            <BlogRecentPosts
              posts={ posts }
              isLoaded={ isLoaded }
              title={ title }
              extraClass={ extraClass }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogRecentPostsContainer);
