/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React from 'react';

import { showNotification } from 'Store/Notification/Notification.action';

export class CheckoutShippingPlugin {
    aroundRenderActions(args, callback, instance) {
        const {
            selectedShippingMethod,
            showErrorNotification
        } = instance.props;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                <button
                  type="submit"
                  block="Button"
                  disabled={ !selectedShippingMethod }
                  mix={ {
                      block: 'CheckoutShipping',
                      elem: 'Button'
                  } }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (event) => {
                      if (selectedShippingMethod.method_code === 'paczkomaty'
                            && selectedShippingMethod.carrier_code === 'paczkomaty') {
                          const selectedPaczkomat = localStorage.getItem('SELECTED_PACZKOMAT');

                          if (!selectedPaczkomat) {
                              event.preventDefault();
                              event.stopPropagation();
                              showErrorNotification(__('You must select a parcel locker from the list.'));

                              return false;
                          }
                      }

                      return true;
                  } }
                >
                    { __('Proceed to billing') }
                </button>
            </div>
        );
    }

    aroundMapDispatchToProps() {
        return (dispatch) => ({
            showErrorNotification: (message) => dispatch(showNotification('error', message))
        });
    }
}

const {
    aroundRenderActions,
    aroundMapDispatchToProps
} = new CheckoutShippingPlugin();

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderActions: {
                position: 100,
                implementation: aroundRenderActions
            }
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: {
            implementation: aroundMapDispatchToProps
        }
    }
};
