/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import Link from 'SourceComponent/Link/Link.component';

/** @namespace Viallidesign/Component/Link/Component/LinkComponent */
export class LinkComponent extends Link {
    scrollToTop = () => {
        window.scrollTo({
            top: document.querySelector('body').offsetTop,
            behavior: 'instant'
        });
    };

    render() {
        const {
            className,
            bemProps,
            children,
            to,
            ...props
        } = this.props;

        if (!to) {
            return (
                <div { ...props }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  onClick={ this.scrollToElement }
                  href={ to }
                >
                    { children }
                </a>
            );
        }

        const classNameConverted = `${ className } ${ stringify(bemProps) }`;

        if (/^https?:\/\//.test(to)) {
            return (
                <a
                  { ...props }
                  href={ to }
                    // eslint-disable-next-line react/forbid-dom-props
                  className={ classNameConverted }
                >
                    { children }
                </a>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => {
                  props.onClick();
                  this.scrollToTop();
              } }
                // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }
}

export default LinkComponent;
