/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { CART_OVERLAY, CUSTOMER_WISHLIST } from '@scandipwa/scandipwa/src/component/Header/Header.config';

import Link from 'Component/Link';
import OfflineNotice from 'Component/OfflineNotice';
import Header from 'SourceComponent/Header/Header.component';

import './Header.override.style';

/** @namespace Viallidesign/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends Header {
    renderTopMenu() {
        return null;
    }

    isVialliDesignTheme() {
        return document.body.classList.contains('viallidesign');
    }

    renderComparePageButton() {
        const {
            device: {
                isMobile
            } = {},
            isCheckout
        } = this.props;

        if (isCheckout || isMobile) {
            return null;
        }

        return (
            <div
              block="Header"
              elem="CompareButtonWrapper"
              key="compare"
            >
                <Link
                  rel="nofollow"
                  to="compare"
                  key="compare"
                  block="Header"
                  elem="Button"
                  mods={ { type: 'compare' } }
                  aria-label={ __('Compare Page') }
                >
                    <div
                      block="Header"
                      elem="CompareIcon"
                    />
                </Link>
            </div>
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section block="Header" elem="Wrapper">
                <header
                  block="Header"
                  mods={ { name, isHiddenOnMobile, isCheckout } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    { this.renderTopMenu() }
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }

                    </nav>
                    { this.renderMenu() }
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

export default HeaderComponent;
