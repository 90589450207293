/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    IMAGE_LOADED, IMAGE_LOADING,
    IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED
} from '@scandipwa/scandipwa/src/component/Image/Image.config';

import Image from 'SourceComponent/Image/Image.component';

import './Image.override.style.scss';

/** @namespace Viallidesign/Component/Image/Component/ImageComponent */
export class ImageComponent extends Image {
    renderImage() {
        const {
            alt,
            isPlaceholder,
            src,
            style,
            title
        } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading="lazy"
                />
            );
        default:
            return null;
        }
    }
}

export default ImageComponent;
