/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export class CheckoutPaymentPlugin {
    aroundRender(args, callback, instance) {
        const { method: { code } } = instance.props;

        if (code === 'payu_gateway_card') {
            return null;
        }

        return callback(...args);
    }
}

const { aroundRender } = new CheckoutPaymentPlugin();

export const config = {
    'Component/CheckoutPayment/Component': {
        'member-function': {
            render: {
                position: 100,
                implementation: aroundRender
            }
        }
    }
};

export default config;
