/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Image from 'Component/Image';
import MenuItem from 'SourceComponent/MenuItem/MenuItem.component';
import media from 'Util/Media';

import './MenuItem.override.style.scss';

/** @namespace Viallidesign/Component/MenuItem/Component/MenuItemComponent */
export class MenuItemComponent extends MenuItem {
    renderItemContentImage(icon, itemMods) {
        if (!icon) {
            return null;
        }

        return (
            <Image
              mix={ {
                  block: 'Menu',
                  elem: 'Image',
                  mods: itemMods
              } }
              src={ icon && media(icon) }
              ratio="custom"
            />
        );
    }
}

export default MenuItemComponent;
