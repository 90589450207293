/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import axios from 'axios';

import { getAuthorizationToken } from 'Util/Auth';

/** @namespace Paczkomaty/Util/ApiClient/ApiClient */
export class ApiClient {
    static async get(requestParams) {
        if (typeof requestParams === 'string') {
            requestParams = {
                url: requestParams
            };
        }

        return this._makeRequest('GET', requestParams);
    }

    static async post(requestParams) {
        return this._makeRequest('POST', requestParams);
    }

    static async _makeRequest(
        method = 'GET',
        {
            url,
            data,
            headers,
            contentType
        }
    ) {
        if (!url) {
            throw new Error(
                'You have to specify \'url\' to make request.'
            );
        }

        const authToken = getAuthorizationToken();

        headers = {
            'Content-Type': contentType || 'application/json',
            ...headers
        };

        if (authToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${ authToken }`
            };
        }

        try {
            const response = await axios({
                method,
                url,
                data,
                headers
            });

            return response.data;
        } catch (error) {
            throw {
                data: error.response.data,
                status: error.response.status,
                statusText: error.response.statusText
            };
        }
    }
}

export default ApiClient;
