/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ProductLinks }
    from 'SourceComponent/ProductLinks/ProductLinks.component';

import ProductCard from '../ProductCard/ProductCard.container';

import './ProductLinks.override.style';

/** @namespace Viallidesign/Component/ProductLinks/Component/ProductLinksComponent */
export class ProductLinksComponent extends ProductLinks {
    static defaultProps = {
        ...ProductLinks.defaultProps,
        numberOfProductsToDisplay: 8
    };

    renderProductCard(product, i) {
        const {
            productCardProps,
            productCardFunctions
        } = this.props;
        const { id = i } = product;

        return (
            <ProductCard
              block="ProductLinks"
              elem="Card"
              product={ product }
              key={ id }
              showLookBookImage
              { ...productCardProps }
              { ...productCardFunctions }
            />
        );
    }
}

export default ProductLinksComponent;
