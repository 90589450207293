/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { getQueryParam } from 'Util/Url';

import { CategoriesDispatcher } from '../../store/Categories';
import { PostsDispatcher } from '../../store/Posts';
import PostsListing from './PostsListing.component';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Blog/Route/PostsListing/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    posts: state.PostsReducer.posts,
    isLoaded: state.PostsReducer.isLoaded,
    categories: state.CategoriesReducer.categories,
    posts_per_page: state.ConfigReducer.blog_posts_per_page
});

/** @namespace Blog/Route/PostsListing/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestPosts: (options) => PostsDispatcher.handleData(dispatch, options),
    requestCategories: (options) => CategoriesDispatcher.handleData(dispatch, options),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName))
});

/** @namespace Blog/Route/PostsListing/Container/PostsListingContainer */
export class PostsListingContainer extends PureComponent {
    static propTypes = {
        requestPosts: PropTypes.func.isRequired,
        requestCategories: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        posts: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        categories: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        posts_per_page: PropTypes.number.isRequired
    };

    __construct(props) {
        super.__construct(props);

        const { posts_per_page } = this.props;

        this.state = {
            category: ''
        };
        this.options = {
            postsOptions: {
                sort: 'DESC',
                pageSize: posts_per_page,
                getDescription: true,
                getMedia: true
            }
        };

        this.containerFunctions = {
            requestPosts: this.requestPosts.bind(this),
            requestCategories: this.requestCategories.bind(this),
            loadMore: this.loadMore.bind(this)
        };
    }

    componentDidMount() {
        this.requestPosts();
        this.requestCategories();
    }

    componentDidUpdate() {
        const { category: prevCategory } = this.state;
        if (this.getCategoryFromUrl() !== prevCategory) {
            this.updateCategory();
        }
    }

    getCategoryFromUrl() {
        return getQueryParam('category', location) || '';
    }

    updateCategory() {
        const category = this.getCategoryFromUrl();

        const filter = {};

        if (category) {
            filter.category_id = {
                eq: category
            };
        }

        this.setState({ category });
        this.requestPosts({ filter });
    }

    requestPosts(options) {
        const { requestPosts } = this.props;
        const { postsOptions } = this.options;

        requestPosts({ ...postsOptions, ...options });
    }

    requestCategories() {
        const { requestCategories } = this.props;
        requestCategories();
    }

    loadMore() {
        const {
            postsOptions,
            postsOptions: { pageSize }
        } = this.options;
        const { posts_per_page } = this.props;

        this.options = {
            postsOptions: {
                ...postsOptions,
                pageSize: pageSize + posts_per_page
            }
        };

        this.requestPosts();
    }

    render() {
        const { category } = this.state;
        const { postsOptions: { pageSize } } = this.options;

        return (
            <PostsListing
              { ...this.props }
              { ...this.containerFunctions }
              category={ category }
              pageSize={ pageSize }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsListingContainer);
