/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { Field } from 'Util/Query';

class ProductListQueryPlugin {
    // eslint-disable-next-line no-unused-vars
    afterGetProductListQuery(args, callback, instance) {
        const fields = callback(...args);

        fields.push(new Field('product_labels').addFieldList([
            'label_code',
            'label_text',
            'label_class'
        ]));

        fields.push(new Field('product_configurable_variants').addFieldList([
            'color',
            'colorLabel',
            'url',
            'capacity',
            'is_color_variant',
            'is_capacity_variant'
        ]));

        return fields;
    }
}

const { afterGetProductListQuery } = new ProductListQueryPlugin();

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: {
                position: 10,
                implementation: afterGetProductListQuery
            }
        }
    }
};
