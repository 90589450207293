/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Logo from 'Component/Logo/Logo.container';
import NavigationTabs from 'SourceComponent/NavigationTabs/NavigationTabs.component';
import media, { LOGO_MEDIA } from 'SourceUtil/Media/Media';

import './NavigationTabs.override.style';

/** @namespace Viallidesign/Component/NavigationTabs/Component/NavigationTabsComponent */
export class NavigationTabsComponent extends NavigationTabs {
    renderHomeButton() {
        const {
            onHomeButtonClick,
            header_logo_src,
            logo_alt,
            onMenuButtonClick
        } = this.props;

        return (
            <div block="NavigationTabs" elem="Wrapper" key="home">

                <button
                  block="NavigationTabs"
                  elem="Button"
                  aria-label="Home"
                  onClick={ onMenuButtonClick }
                >
                    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1H24M0 8H24M0 15H24" stroke="white" strokeWidth="1.5" />
                    </svg>

                </button>

                <button
                  block="NavigationTabs"
                  elem="Button Logo"
                  aria-label="Home"
                  onClick={ onHomeButtonClick }
                >

                    <Logo
                      src={ media(header_logo_src, LOGO_MEDIA) }
                      alt={ logo_alt }
                    />

                </button>
            </div>

        );
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick } = this.props;

        return (
            <button
              key="menu"
              block="NavigationTabs"
              elem="Button"
              aria-label="Go to menu and search"
              onClick={ onMenuButtonClick }
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ {
                      block: 'NavigationTabs',
                      elem: 'Icon',
                      mods: { isActive }
                  } }
                  mods={ {
                      isVisible: true,
                      type: 'loop'
                  } }
                >
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.76563" cy="7.76563" r="7.01563" stroke="white" strokeWidth="1.5" />
                        <path d="M17.75 17.75L12.2031 12.2031" stroke="white" strokeWidth="1.5" />
                    </svg>
                </div>
            </button>
        );
    }

    renderMinicartButton(isActive = false) {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
              key="mincart"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMinicartButtonClick }
              aria-label="Minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ {
                      block: 'NavigationTabs',
                      elem: 'Icon',
                      mods: { isActive }
                  } }
                  mods={ {
                      isVisible: true,
                      type: 'minicart'
                  } }
                >

                    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.03125" y="6.1875" width="16.25" height="14.0313" stroke="white" strokeWidth="1.5" />
                        <path
                            /* eslint-disable-next-line max-len */
                          d="M5.82812 10.9844V4.45139C5.82812 2.54524 7.31818 1 9.15625 1C10.9943 1 12.4844 2.54524 12.4844 4.45139V10.9844"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                    </svg>

                    { this.renderMinicartItemsQty() }
                </div>
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick } = this.props;

        return (
            <button
              key="account"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
            >
                <div
                  block="Header"
                  elem="Button"
                  mix={ {
                      block: 'NavigationTabs',
                      elem: 'Icon',
                      mods: { isActive }
                  } }
                  mods={ {
                      isVisible: true,
                      type: 'account'
                  } }
                >
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            // eslint-disable-next-line max-len
                          d="M16.2813 18.75C16.2813 14.4612 12.8045 10.9844 8.51563 10.9844C4.22679 10.9844 0.75 14.4612 0.75 18.75"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        { /* eslint-disable-next-line max-len */ }
                        <circle cx="8.51562" cy="4.32812" r="3.32813" stroke="white" strokeWidth="1.5" />
                    </svg>

                </div>

            </button>
        );
    }
}

export default NavigationTabsComponent;
