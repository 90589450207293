/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import BlogRelatedPosts from './BlogRelatedPosts.component';

/** @namespace Blog/Component/BlogRelatedPosts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    related_posts: state.PostsDetailsReducer.post.related_posts,
    isLoaded: state.PostsDetailsReducer.post.post_id
});

/** @namespace Blog/Component/BlogRelatedPosts/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlogRelatedPosts);
