/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React, { PureComponent } from 'react';

import { isSignedIn } from 'Util/Auth';

import ApiClient from '../../util/ApiClient';

import './PaczkomatyShippingDetails.style.scss';

/** @namespace Paczkomaty/Component/PaczkomatyShippingDetails/Component/PaczkomatyShippingDetailsComponent */
export class PaczkomatyShippingDetailsComponent extends PureComponent {
    state = {
        selectedPaczkomat: false,
        openModal: false
    };

    componentDidMount() {
        this.easyPackInit();
    }

    easyPackInit() {
        if (!window.easyPack) {
            return;
        }
        window.easyPack.init({});
        window.easyPack.mapWidget('easypack-map', (point) => {
            const {
                address: {
                    line1,
                    line2
                },
                name
            } = point;

            const paczkomatId = [line1, line2, name].join(', ');

            localStorage.setItem('SELECTED_PACZKOMAT', paczkomatId);

            const { data: cartId } = JSON.parse(window.localStorage.getItem('guest_quote_id'));

            const requestUrl = isSignedIn()
                ? '/rest/V1/carts/mine/set-paczkomat'
                : `/rest/V1/guest-carts/${ cartId }/mine/set-paczkomat`;

            ApiClient.post({
                url: requestUrl,
                data: {
                    cartId,
                    paczkomat: {
                        paczkomatId
                    }
                }
            });

            this.setState(() => ({
                selectedPaczkomat: paczkomatId,
                openModal: false
            }));
        });
    }

    toggleModal() {
        this.setState((prevState) => ({
            openModal: !prevState.openModal
        }));
    }

    modalButton() {
        return (
            <span
              role="presentation"
              type="button"
              block="Button"
              onClick={ () => {
                  this.toggleModal();
              } }
            >
                { __('Select Paczkomat') }
            </span>
        );
    }

    selectedPaczkomat() {
        const {
            selectedPaczkomat
        } = this.state;

        if (!selectedPaczkomat) {
            return null;
        }

        return (
            <div
              block="Paczkomaty"
              elem="SelectedPaczkomatInforamtion"
            >
                { `${ __('Selected') }:${ selectedPaczkomat }` }
            </div>
        );
    }

    render() {
        const { openModal } = this.state;

        return (
            <div block="Paczkomaty" elem="PointSelector">
                <h2 block="Checkout" elem="Heading">Paczkomat InPost</h2>

                { this.modalButton() }
                { this.selectedPaczkomat() }

                <div
                  block="Paczkomaty"
                  elem="Modal"
                  mods={ { Open: openModal } }
                >
                    <div id="easypack-map">
                    <span
                      role="presentation"
                      type="button"
                      block="CloseModal"
                      onClick={ () => {
                          this.toggleModal();
                      } }
                    />
                    </div>

                </div>
            </div>
        );
    }
}

export default PaczkomatyShippingDetailsComponent;
