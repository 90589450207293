/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';

import { STATUS_DISABLED, STATUS_ENABLED } from './PayUPaymentLink.config';

import './PayUPaymentLink.style.scss';

/** @namespace Payu/Component/PayUPaymentLink/Component/PayUPaymentLinkComponent */
export class PayUPaymentLinkComponent extends PureComponent {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        setPaymentLink: PropTypes.func.isRequired,
        linkData: PropTypes.shape({
            brandImageUrl: PropTypes.string,
            name: PropTypes.string,
            status: PropTypes.oneOf([STATUS_ENABLED, STATUS_DISABLED])
        }).isRequired
    };

    render() {
        const {
            active,
            setPaymentLink,
            linkData
        } = this.props;

        const {
            brandImageUrl,
            name,
            status
        } = linkData;

        if (status === PayUPaymentLinkComponent.STATUS_DISABLED) {
            return null;
        }

        return (
            <div
              role="presentation"
              block="PayUPaymentLinks"
              elem="SingleLink"
              mods={ { active } }
              title={ name }
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => {
                  setPaymentLink(linkData);
              } }
            >
                <img src={ brandImageUrl } alt={ name } />
            </div>
        );
    }
}

export default PayUPaymentLinkComponent;
