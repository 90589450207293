/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { HistoryType } from 'Type/Common';

import BlogCategories from '../../component/BlogCategories';
import BlogRecentPosts from '../../component/BlogRecentPosts';
import BlogRelatedPosts from '../../component/BlogRelatedPosts';
import BlogRelatedProducts from '../../component/BlogRelatedProducts';
import { convertDateFormat } from '../../util/Date';
import { POSTS_DETAILS } from './PostsDetails.config';

import './PostsDetails.style';

/**
 * @class PostsDetails
 * @namespace Blog/Route/PostsDetails/Component/PostsDetailsComponent */
export class PostsDetailsComponent extends PureComponent {
    static propTypes = {
        requestPosts: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        history: HistoryType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        post: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                handle: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.options = {
            postOptions: {
                getDescription: true,
                getMedia: true,
                getRelated: true
            }
        };
    }

    componentDidMount() {
        this.requestPosts();
        this.getPost();
        this.setHeaderState();
        this.storePathname();
    }

    componentDidUpdate() {
        this.updateBreadcrumbs();
        this.updatePage();
        this.updateMeta();
    }

    setHeaderState() {
        const {
            setHeaderState,
            history
        } = this.props;

        setHeaderState({
            name: POSTS_DETAILS,
            title: 'Blog',
            onBackClick: () => history.goBack()
        });
    }

    updateMeta() {
        const {
            post: {
                title,
                meta_title,
                meta_description,
                meta_keyword
            },
            updateMeta
        } = this.props;

        updateMeta({
            title,
            meta_title,
            meta_description,
            meta_keyword,
            canonical_url: this.getCanonicalUrl() || ''
        });
    }

    /**
     * Get url handle from router
     * @return {void | jsx}
     */
    getUrlParam() {
        const { match: { params: { handle } } } = this.props;
        return handle;
    }

    getPost() {
        const { post } = this.props;
        return post;
    }

    /**
     * Dispatch breadcrumbs update
     * @return {void}
     */
    updateBreadcrumbs() {
        const {
            updateBreadcrumbs,
            post
        } = this.props;
        const { title } = post;

        if (!title) {
            return;
        }

        const breadcrumbs = [
            {
                name: title
            },
            {
                url: '/blog',
                name: __('Blog')
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    /**
     * Stores the old handle in state so that
     * it can compare it when a handle is changed
     */
    storePathname() {
        this.setState({ oldHandle: this.getUrlParam() });
    }

    /**
     * Gets the canonical url for the specific post
     */
    getCanonicalUrl() {
        const { post: { identifier } } = this.props;

        if (!identifier) {
            return null;
        }

        return `${ window.location.origin }/blog/${ identifier }`;
    }

    /**
     * Updates the page if handle is changed
     */
    updatePage() {
        const { oldHandle } = this.state;

        if (oldHandle !== this.getUrlParam()) {
            this.requestPosts();
            this.getPost();
            this.setHeaderState();
            this.storePathname();
        }
    }

    requestPosts() {
        const { requestPosts } = this.props;
        const { postOptions } = this.options;

        requestPosts({
            ...postOptions,
            id: this.getUrlParam()
        });
    }

    renderTitle() {
        const { post: { title } } = this.props;

        return (
            <h1 mix={ {
                block: 'PostsDetails',
                elem: 'Title'
            } }
            >
                <TextPlaceholder length="medium" content={ title } />
            </h1>
        );
    }

    /**
     * Renders the featured image
     * @returns {*}
     */
    renderImage() {
        const {
            post: {
                first_image,
                post_id,
                title
            }
        } = this.props;

        if (!first_image && post_id) {
            return null;
        }

        return (
            <Image
              ratio="4x3"
              src={ first_image }
              alt={ __('Post thumbnail %s.', title) }
              isPlaceholder={ !first_image }
            />
        );
    }

    renderPublishDate() {
        const { post: { publish_time } } = this.props;

        if (!publish_time) {
            return null;
        }

        return (
            <div mix={ {
                block: 'PostsDetails',
                elem: 'Date'
            } }
            >
                <TextPlaceholder
                  mix={ {
                      block: 'BlogRecentPosts',
                      elem: 'DatePlaceholder'
                  } }
                  content={ convertDateFormat(publish_time) }
                />
            </div>
        );
    }

    renderAuthorName() {
        const { post: { author } } = this.props;

        if (!author) {
            return null;
        }

        return (
            <div mix={ {
                block: 'PostsDetails',
                elem: 'Author'
            } }
            >
                { author.name }
            </div>
        );
    }

    renderContent() {
        const { post: { filtered_content } } = this.props;

        if (!filtered_content) {
            return (
                <TextPlaceholder
                  mix={ {
                      block: 'PostsDetails',
                      elem: 'ContentPlaceholder'
                  } }
                  length="custom"
                />
            );
        }

        return (
            <div mix={ {
                block: 'PostsDetails',
                elem: 'Content'
            } }
            >
                <Html content={ filtered_content } />
            </div>

        );
    }

    renderAuthor() {
        const { post } = this.props;
        const { author } = post || {};
        let { name } = author || {};

        if (!name) {
            return;
        }

        name = 'mepalpolska.pl';

        // eslint-disable-next-line consistent-return
        return (

            <div
              block="BlogPostCard"
              elem="Author"
            >
                { __('Author: %s', name) }
            </div>
        );
    }

    // eslint-disable-next-line no-dupe-class-members
    renderPublishDate() {
        const {
            post: { publish_time }
        } = this.props;

        if (!publish_time) {
            return;
        }

        // console.log( publish_time );

        const convertDate = convertDateFormat(publish_time);

        // console.log(publish_time);
        // console.log(convertDate);
        //
        // if (convertDate) {
        //     return 'tets';
        // }
        //
        // return 'test2';

        return (

            <div
              block="BlogPostCard"
              elem="PublishDate"
            >
                { convertDateFormat(publish_time) }
            </div>
        );
    }

    render() {
        return (
            <main block="PostsDetails">
                <ContentWrapper
                  wrapperMix={ {
                      block: 'PostsDetails',
                      elem: 'Wrapper'
                  } }
                  label="Post"
                >
                    { this.renderTitle() }

                    <div block="PostsDetails" elem="ColumnWrapper">
                        <div block="PostsDetails" elem="Description">
                            { this.renderImage() }

                            { this.renderContent() }
                            <div block="PostsDetails" elem="Extra-Information">
                                { this.renderAuthor() }
                                { this.renderPublishDate() }
                            </div>
                        </div>

                        <div block="PostsDetails" elem="RelatedPosts">
                            <BlogRelatedPosts />
                        </div>

                    </div>

                </ContentWrapper>
                <BlogRelatedProducts />
            </main>
        );
    }
}

export default PostsDetailsComponent;
