/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React from 'react';

import PayUPayment from '../component/PayUPayment';

export class CheckoutPaymentsPlugin {
    renderPayUPayment(args, callback, instance) {
        return (
            <PayUPayment setLoading={ instance.props.setLoading } />
        );
    }

    aroundPaymentRenderMap(originalMember, instance) {
        return {
            ...originalMember,
            payu_gateway: instance.renderPayUPayment.bind(instance)
        };
    }
}

const { aroundPaymentRenderMap, renderPayUPayment } = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderPayUPayment: {
                position: 100,
                implementation: renderPayUPayment
            }
        },
        'member-property': {
            paymentRenderMap: {
                position: 100,
                implementation: aroundPaymentRenderMap
            }
        }
    }
};

export default config;
