/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import qs from 'qs';

import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import { DETAILS_STEP, PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';

import ApiClient from '../util/ApiClient';

export class CheckoutContainerPlugin {
    aroundComponentDidMount(args, callback, instance) {
        const { location } = instance.props;

        if (location.pathname === '/checkout/onepage/success/') {
            const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            const isError = !!queryParams.error;

            instance.setLoading(true);

            ApiClient.get(`/ageno_payu/data/getAfterOrderData?_=${new Date().getTime()}`)
                .then(
                    /** @namespace AgenoScandiPWA/PayuGateway/Plugin/Plugin/getThen */
                    (response) => {
                        instance.setLoading(false);
                        instance.setDetailsStep(response.order_id, isError);
                    }
                )
                .catch(
                    /** @namespace AgenoScandiPWA/PayuGateway/Plugin/Plugin/getThenCatch */
                    () => {
                        instance.setLoading(false);
                    }
                );

            return true;
        }

        return callback();
    }

    aroundSavePaymentInformation(args, callback, instance) {
        const { resetCart } = instance.props;
        const [paymentInformation] = args;

        const { paymentMethod } = paymentInformation;

        if (paymentMethod.code === 'payu_gateway') {
            const { email } = instance.state;
            const { billing_address } = paymentInformation;

            const { data: cartId } = JSON.parse(window.localStorage.getItem('guest_quote_id'));
            const payuPaymentData = JSON.parse(window.localStorage.getItem('payu_payment_data'));
            const { paymentAdditionalData } = payuPaymentData || {};

            if (!paymentAdditionalData || !paymentAdditionalData.payu_method) {
                instance._handleError([{
                    message: 'Musisz wybrać metodę płatności PayU'
                }]);

                return false;
            }

            instance.setLoading(true);

            const requestUrl = isSignedIn()
                ? '/rest/V1/carts/mine/payment-information'
                : `/rest/V1/guest-carts/${cartId}/payment-information`;

            ApiClient.post({
                url: requestUrl,
                data: {
                    cartId,
                    billingAddress: {
                        ...billing_address,
                        street: Array.isArray(billing_address.street) ? billing_address.street : [
                            billing_address.street
                        ]
                    },
                    paymentMethod: {
                        method: 'payu_gateway',
                        additional_data: {
                            ...paymentAdditionalData
                        }
                    },
                    email
                }
            })
                .then(
                    /** @namespace AgenoScandiPWA/PayuGateway/Plugin/Plugin/postThen */
                    (orderId) => {
                        ApiClient.get({
                            url: `/ageno_payu/data/getPostPlaceOrderData?_=${new Date().getTime()}&orderId=${orderId}`
                        })
                            .then(
                                /** @namespace AgenoScandiPWA/PayuGateway/Plugin/getThen */
                                (response) => {
                                    if (response.success) {
                                        if (!isSignedIn()) {
                                            BrowserDatabase.deleteItem(getGuestQuoteId());
                                        }

                                        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
                                        resetCart();

                                        window.location = response.redirectUri;
                                    }
                                }
                            )
                            .catch(
                                /** @namespace AgenoScandiPWA/PayuGateway/Plugin/getThenCatch */
                                (error) => {
                                    instance._handleError([{
                                        message: error.data.message
                                    }]);
                                }
                            );
                    }
                )
                .catch(
                    /** @namespace AgenoScandiPWA/PayuGateway/Plugin/Plugin/postThenCatch */
                    (error) => {
                        instance._handleError([{
                            message: error.data.message
                        }]);
                    }
                );

            return true;
        }

        return callback(...args);
    }

    aroundOnShippingEstimationFieldsChange(args, callback, instance) {
        const { location } = instance.props;

        if (location.pathname === '/checkout/onepage/success/') {
            return false;
        }

        return callback(...args);
    }

    aroundSetDetailsStep(args, callback, instance) {
        const [orderID, isError = false] = args;
        const { resetCart, resetGuestCart, setNavigationState } = instance.props;

        // For some reason not logged in user cart preserves qty in it
        if (!isSignedIn()) {
            // BrowserDatabase.deleteItem(getGuestQuoteId());
            deleteGuestQuoteId();
        }

        BrowserDatabase.deleteItem(PAYMENT_TOTALS);

        // For guest we can just update cart without creating new quote id
        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }

        const newState = {
            isLoading: false,
            paymentTotals: {},
            checkoutStep: DETAILS_STEP,
            orderID
        };

        if (isError) {
            newState.isError = true;
        }

        instance.setState(newState);

        setNavigationState({
            name: CART_TAB
        });
    }
}

const {
    aroundComponentDidMount,
    aroundSavePaymentInformation,
    aroundOnShippingEstimationFieldsChange,
    aroundSetDetailsStep
} = new CheckoutContainerPlugin();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentInformation: {
                position: 100,
                implementation: aroundSavePaymentInformation
            },
            componentDidMount: {
                position: 100,
                implementation: aroundComponentDidMount
            },
            onShippingEstimationFieldsChange: {
                position: 100,
                implementation: aroundOnShippingEstimationFieldsChange
            },
            setDetailsStep: {
                position: 100,
                implementation: aroundSetDetailsStep
            }
        }
    }
};
