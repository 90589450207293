/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React from 'react';

import CheckoutSuccess from 'Component/CheckoutSuccess';
import { DETAILS_STEP } from 'Route/Checkout/Checkout.config';

export class CheckoutComponentPlugin {
    aroundRenderGuestForm(args, callback, instance) {
        if (instance.props.checkoutStep === DETAILS_STEP) {
            return null;
        }

        return callback(...args);
    }

    aroundRenderDetailsStep(args, callback, instance) {
        const { orderID, isError, totals, shippingAddress, email } = instance.props;

        return (
            <CheckoutSuccess
              orderID={ orderID }
              isError={ isError }
              totals = { totals }
              shippingAddress = { shippingAddress }
              email = { email }
            />
        );
    }
}

const { aroundRenderDetailsStep, aroundRenderGuestForm } = new CheckoutComponentPlugin();

export default {
    'Route/Checkout/Component': {
        'member-function': {
            renderGuestForm: {
                position: 100,
                implementation: aroundRenderGuestForm
            },
            renderDetailsStep: {
                position: 100,
                implementation: aroundRenderDetailsStep
            }
        }
    }
};
