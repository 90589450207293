/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Logo from 'SourceComponent/Logo/Logo.component';

/** @namespace Viallidesign/Component/Logo/Component/LogoComponent */
export class LogoComponent extends Logo {
    renderPlaceholderLogo() {
        return null;
    }
}

export default LogoComponent;
