/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { Component } from 'react';

import ApiClient from '../../util/ApiClient';
import PayUPaymentLink from '../PayUPaymentLink';

import './PayUPayment.style.scss';

/** @namespace Payu/Component/PayUPayment/Component/PayUPaymentComponent */
export class PayUPaymentComponent extends Component {
    localStorageKey = 'payu_payment_data';

    state = {
        links: [],
        paymentAdditionalData: {
            payu_method: null,
            payu_method_type: 'PBL'
        },
        selectedPaymentLink: null
    };

    static propTypes = {
        setLoading: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.setPaymentLink = this.setPaymentLink.bind(this);
    }

    componentDidMount() {
        const { setLoading } = this.props;

        window.localStorage.removeItem(this.localStorageKey);
        setLoading(true);

        ApiClient.get(`/ageno_payu/data/getPayUPaymentsData?_=${ new Date().getTime() }`)
            .then(
                /** @namespace Payu/Component/PayUPayment/Component/get/then */
                (response) => {
                    this.setState({
                        links: response
                    });
                    setLoading(false);
                }
            )
            .catch(
                /** @namespace Payu/Component/PayUPayment/Component/get/then/catch */
                () => {
                    this.setState({
                        links: []
                    });
                    setLoading(false);
                }
            );
    }

    setPaymentLink(paymentLink) {
        const { paymentAdditionalData } = this.state;

        const newState = {
            selectedPaymentLink: paymentLink,
            paymentAdditionalData: {
                ...paymentAdditionalData,
                payu_method: paymentLink.value
            }
        };

        this.setState(newState);

        if (this.localStorageKey) {
            window.localStorage.setItem(this.localStorageKey, JSON.stringify(newState));
        }
    }

    render() {
        const {
            selectedPaymentLink,
            links
        } = this.state;

        return (
            <div block="PayUPayment">
                <h2 block="Checkout" elem="Heading">PayU</h2>
                <div block="PayUPaymentLinks">
                    { links && Array.isArray(links) && links.length ? links.map((link) => (
                        <PayUPaymentLink
                          linkData={ link }
                          key={ link.value }
                          active={ selectedPaymentLink ? link.value === selectedPaymentLink.value : false }
                          setPaymentLink={ this.setPaymentLink }
                        />
                    )) : null }
                </div>
            </div>
        );
    }
}

export default PayUPaymentComponent;
