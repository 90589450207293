/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductLabel.styles';

/** @namespace ProductLabel/Component/ProductLabel/Component/ProductLabelComponent */
export class ProductLabelComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/require-default-props
        product_labels: PropTypes.arrayOf(PropTypes.shape({
            label_text: PropTypes.string,
            label_code: PropTypes.string,
            label_class: PropTypes.string
        })).isRequired
    };

    static defaultProps = {
        // eslint-disable-next-line react/default-props-match-prop-types
        product_labels: []
    };

    render() {
        const { product_labels } = this.props;

        return (
            <div block="special_status_product_list_item">
                { product_labels.map(({
                    label_code,
                    label_text,
                    label_class
                }) => (
                    <div
                      block={ label_class }
                      key={ label_code }
                    >
                        { label_text }
                    </div>
                )) }
            </div>
        );
    }
}

export default ProductLabelComponent;
