/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import NavigationTabsContainer from 'SourceComponent/NavigationTabs/NavigationTabs.container';

/** @namespace Viallidesign/Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    header_logo_src: state.ConfigReducer.header_logo_src,
    logo_alt: state.ConfigReducer.logo_alt
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
