/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React from 'react';

import PaczkomatyShippingDetails from '../component/PaczkomatyShippingDetails';

export class CheckoutDeliveryOptionsPlugin {
    aroundComponentDidMount(args, callback) {
        window.easyPackAsyncInit = function () {
            window.easyPack.init({
                defaultLocale: 'pl',
                mapType: 'osm',
                searchType: 'osm',
                points: {
                    types: ['parcel_locker']
                },
                map: {
                    initialTypes: ['parcel_locker']
                }
            });
        };

        localStorage.removeItem('SELECTED_PACZKOMAT');

        return callback();
    }

    aroundShippingRenderMap(originalMember) {
        return {
            ...originalMember,
            paczkomaty: () => (
                <PaczkomatyShippingDetails />
            )
        };
    }
}

const {
    aroundComponentDidMount,
    aroundShippingRenderMap
} = new CheckoutDeliveryOptionsPlugin();

export default {
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            componentDidMount: {
                position: 100,
                implementation: aroundComponentDidMount
            }
        },
        'member-property': {
            shippingRenderMap: {
                position: 100,
                implementation: aroundShippingRenderMap
            }
        }
    }
};
