/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line import/prefer-default-export
export function convertDateFormat(
    timestamp,
    date_options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
) {
    if (!timestamp) {
        return;
    }

    // eslint-disable-next-line no-param-reassign
    timestamp = timestamp.replace(/ /g, 'T');
    const date = new Date(timestamp);

    return date.toLocaleDateString(undefined, date_options);
}
