/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import { MixType } from 'Type/Common';

import BlogSearchOverlay from '../BlogSearchOverlay';
import { BLOG_SEARCH } from './BlogSearchBar.config';

import './BlogSearchBar.style';

/** @namespace Blog/Component/BlogSearchBar/Component/BlogSearchBarComponent */
export class BlogSearchBarComponent extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            searchCriteria: '',
            isSearchVisible: false
        };
        this.onSearchBarChange = this.onSearchBarChange.bind(this);
        this.onSearchOutsideClick = this.onSearchOutsideClick.bind(this);
        this.onSearchBarClick = this.onSearchBarClick.bind(this);
    }

    onSearchOutsideClick() {
        const {
            hideActiveOverlay,
            goToPreviousNavigationState
        } = this.props;
        const { isSearchVisible } = this.state;

        if (!isSearchVisible) {
            return;
        }

        hideActiveOverlay();
        goToPreviousNavigationState();

        this.setState({ isSearchVisible: false });
    }

    setHeaderState() {
        const { setHeaderState } = this.props;
        setHeaderState({
            name: BLOG_SEARCH
        });
    }

    onSearchBarClick() {
        const { showOverlay } = this.props;
        const { isSearchVisible } = this.state;

        if (isSearchVisible) {
            return;
        }

        this.setState({ isSearchVisible: true });
        showOverlay(BLOG_SEARCH);
        this.setHeaderState();
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    render() {
        const {
            searchCriteria,
            isSearchVisible
        } = this.state;
        const { mix } = this.props;

        return (
            <div mix={ mix }>
                <ClickOutside onClick={ this.onSearchOutsideClick }>
                    <div
                      block="BlogSearchBar"
                      elem="SearchWrapper"
                      aria-label="Search"
                    >
                        <div block="BlogSearchBar" elem="Search">
                            <input
                              id="posts-search-field"
                              placeholder="Search"
                              block="BlogSearchBar"
                              elem="SearchField"
                              onClick={ this.onSearchBarClick }
                              onChange={ this.onSearchBarChange }
                              value={ searchCriteria }
                              mods={ { type: 'searchField' } }
                            />
                        </div>
                        <BlogSearchOverlay
                          searchCriteria={ searchCriteria }
                          onSearchOutsideClick={ this.onSearchOutsideClick }
                          isSearchVisible={ isSearchVisible }
                        />
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default BlogSearchBarComponent;
