/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import { PostsSearchDispatcher } from '../../store/PostsSearch';
import BlogSearchOverlay from './BlogSearchOverlay.component';

/** @namespace Blog/Component/BlogSearchOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    searchResults: state.PostsSearchReducer.postsInSearch,
    isLoading: state.PostsSearchReducer.isLoading
});

/** @namespace Blog/Component/BlogSearchOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    makeSearchRequest: (options) => PostsSearchDispatcher.handleData(dispatch, options),
    clearSearchResults: () => PostsSearchDispatcher.clearSearchResults(dispatch)
});

/** @namespace Blog/Component/BlogSearchOverlay/Container/BlogSearchOverlayContainer */
export class BlogSearchOverlayContainer extends PureComponent {
    static propTypes = {
        makeSearchRequest: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired
    };

    containerFunctions = {
        makeSearchRequest: this.makeSearchRequest.bind(this)
    };

    makeSearchRequest() {
        const {
            makeSearchRequest,
            clearSearchResults,
            searchCriteria
        } = this.props;

        if (searchCriteria) {
            clearSearchResults();
            makeSearchRequest({
                filter: `{ search: { eq: ${ encodeURIComponent(searchCriteria) } } }`,
                getMedia: true
            });
        }
    }

    render() {
        return (
            <BlogSearchOverlay
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogSearchOverlayContainer);
