/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductLabel from 'product-label/src/component/ProductLabel.component';

import { PRODUCT_OUT_OF_STOCK } from 'Component/CartItem/CartItem.config';
import AddToCart from 'SourceComponent/AddToCart';
import Loader from 'SourceComponent/Loader';
import ProductCard from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.override.styles.scss';

/**
 * Product card
 * @class ProductCard
 * @namespace Viallidesign/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends ProductCard {
    renderProductLabel() {
        const { product: { product_labels } } = this.props;

        return (
            <ProductLabel product_labels={ product_labels } />
        );
    }

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product,
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            product: {
                stock_status
            }
        } = this.props;

        if (stock_status === PRODUCT_OUT_OF_STOCK || !stock_status) {
            return null;
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ {
                  block: 'ProductActions',
                  elem: 'AddToCart'
              } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
            />
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading
        } = this.props;

        return (
            <li block="ProductCard" mix={ mix }>
                <Loader isLoading={ isLoading } />

                { this.renderCardLinkWrapper(
                    <>
                        { this.renderProductLabel() }
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                        <div block="ProductCard" elem="Content">
                            { this.renderReviews() }
                            { this.renderProductPrice() }
                            { this.renderVisualConfigurableOptions() }
                            { this.renderTierPrice() }
                            { this.renderMainDetails() }
                            { this.renderAdditionalProductDetails() }
                        </div>
                    </>
                ) }
                <div block="ProductCard" elem="AdditionalContent">
                    { this.renderAddToCart() }
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
