/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';

import { convertDateFormat } from '../../util/Date';
import { getPostUrl } from '../../util/Posts';

import './BlogPostCard.style';

/** @namespace Blog/Component/BlogPostCard/Component/BlogPostCardComponent */
export class BlogPostCardComponent extends PureComponent {
    static propTypes = {
        post: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.bool
        ]),
        isPlaceholder: PropTypes.bool,
        isCompactOnMobile: PropTypes.bool
    };

    static defaultProps = {
        post: false,
        isPlaceholder: false,
        isCompactOnMobile: false
    };

    renderImage() {
        const {
            post: {
                first_image,
                title
            },
            post
        } = this.props;

        if (!first_image) {
            return null;
        }

        return (
            <Link
              rel="nofollow"
              to={ getPostUrl(post) }
              title={ __('View %s', title) }
              mix={ {
                  block: 'BlogPostCard',
                  elem: 'ImageWrapper'
              } }
            >
                <Image
                  mix={ {
                      block: 'BlogPostCard',
                      elem: 'Image'
                  } }
                  ratio="4x3"
                  src={ first_image }
                />
            </Link>
        );
    }

    renderTitle() {
        const {
            post,
            post: { title }
        } = this.props;

        if (!title || !post) {
            return null;
        }

        const postUrl = getPostUrl(post);

        return (

            <Link
              block="BlogPostCard"
              elem="Title"
              to={ postUrl }
            >
                { title }
            </Link>
        );
    }

    renderDescription() {
        const { post: { short_content } } = this.props;

        if (!short_content) {
            return null;
        }

        return <Html content={ short_content } />;
    }

    renderAuthor() {
        let {
            post,
            post: { author: { name } }
        } = this.props;

        const postUrl = getPostUrl(post);

        if (!name || !postUrl) {
            return;
        }

        name = 'mepalpolska.pl';

        // eslint-disable-next-line consistent-return
        return (

            <Link
              rel="nofollow"
              block="BlogPostCard"
              elem="Author"
              to={ postUrl }
            >
                { __('Author: %s', name) }
            </Link>
        );
    }

    renderPublishDate() {
        const {
            post,
            post: { publish_time }
        } = this.props;
        const postUrl = getPostUrl(post);

        if (!publish_time || !postUrl) {
            return;
        }

        return (

            <Link
              rel="nofollow"
              block="BlogPostCard"
              elem="PublishDate"
              to={ postUrl }
            >
                { convertDateFormat(publish_time) }
            </Link>

        );
    }

    renderContents() {
        const {
            isPlaceholder,
            post
        } = this.props;

        const postUrl = getPostUrl(post);

        if (isPlaceholder) {
            return null;
        }

        return (
            <div block="BlogPostCard" elem="ContentWrapper" mods={ { expand: true } }>
                { this.renderImage() }
                <div block="BlogPostCard" elem="Details">
                    <div block="BlogPostCard" elem="Extra-Information">
                        { this.renderAuthor() }
                        { this.renderPublishDate() }
                    </div>
                    { this.renderTitle() }

                    <div block="BlogPostCard" elem="DescriptionWrapper">
                        { this.renderDescription() }
                    </div>
                    <Link
                      rel="nofollow"
                      block="BlogRecentPosts"
                      elem="ReadMore"
                      to={ postUrl }
                    >
                        { __('Read more') }
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const {
            isPlaceholder,
            isCompactOnMobile
        } = this.props;

        return (
            <div
              block="BlogPostCard"
              mods={ {
                  isLoading: isPlaceholder,
                  isCompactOnMobile
              } }
            >

                { this.renderContents() }
            </div>
        );
    }
}

export default BlogPostCardComponent;
