/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import { HistoryType } from 'Type/Common';

import BlogCategories from '../../component/BlogCategories';
import BlogPostCard from '../../component/BlogPostCard';
import BlogRecentPosts from '../../component/BlogRecentPosts';
import BlogSearchBar from '../../component/BlogSearchBar';
import { POSTS_LISTING } from './PostsListing.config';

import './PostsListing.style';

/** @namespace Blog/Route/PostsListing/Component/PostsListingComponent */
export class PostsListingComponent extends PureComponent {
    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        history: HistoryType.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        isLoaded: PropTypes.bool,
        pageSize: PropTypes.number.isRequired,
        posts: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        categories: PropTypes.shape({
            items: PropTypes.array,
            count: PropTypes.number
        }).isRequired,
        category: PropTypes.string.isRequired,
        loadMore: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoaded: false
    };

    componentDidMount() {
        this.updateMeta();
        this.setHeaderState();
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            ...this.getCategoryBreadcrumb(),
            {
                url: '/blog',
                name: __('Blog')
            },
            {
                url: '/',
                name: __('Home')
            }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    updateMeta() {
        const { updateMeta } = this.props;

        updateMeta({
            title: __('Blog'),
            canonical_url: this.getCanonicalUrl()
        });
    }

    renderTitle() {
        this.updateBreadcrumbs();
        return (
            <h3 block="CmsPage" elem="Heading">
                { this.getCategoryTitle() || __('Blog') }
            </h3>
        );
    }

    /**
     * Gets the canonical url for the specific post
     */
    getCanonicalUrl() {
        const { category } = this.props;

        if (category) {
            return `${ window.location.origin }/blog?category=${ category }`;
        }

        return `${ window.location.origin }/blog`;
    }

    getCategoryTitle() {
        const {
            category,
            categories: { items }
        } = this.props;
        const { title } = items.find(({ identifier }) => identifier === category) || {};

        return title;
    }

    getCategoryBreadcrumb() {
        const {
            categories: { count },
            category
        } = this.props;
        const title = this.getCategoryTitle() || '';

        if (count && title) {
            return [
                {
                    name: title
                }
            ];
        }

        if (!count && category) {
            return [
                {
                    name: ''
                }
            ];
        }

        return [];
    }

    setHeaderState() {
        const {
            setHeaderState,
            history
        } = this.props;

        setHeaderState({
            name: POSTS_LISTING,
            title: 'Blog',
            onBackClick: () => history.goBack()
        });
    }

    renderGrid() {
        const {
            isLoaded,
            posts: { items },
            pageSize
        } = this.props;

        if (isLoaded && items && items.length) {
            return items.map((post) => (
                <BlogPostCard
                  key={ post.post_id }
                  block="BlogPostCard"
                  post={ post }
                />
            ));
        }

        if (isLoaded) {
            return (
                <span>
                    { __('No posts in this category') }
                </span>
            );
        }

        return Array.from({ length: pageSize }, (_, i) => (
            <BlogPostCard
              key={ i }
              block="BlogPostCard"
              isPlaceholder
            />
        ));
    }

    renderLoadMore() {
        const {
            posts: { count },
            pageSize,
            loadMore
        } = this.props;

        if (count <= pageSize) {
            return null;
        }

        return (
            <div block="PostsListing" elem="LoadMoreWrapper">
                <button
                  block="PostsListing"
                  elem="LoadMoreButton"
                  mix={ { block: 'Button' } }
                  onClick={ loadMore }
                >
                    { __('Load More') }
                </button>
            </div>
        );
    }

    render() {
        return (
            <main block="PostsListing">
                <ContentWrapper label="PostsListing">
                    { this.renderTitle() }
                    <div block="PostsListing" elem="ColumnWrapper">
                        <div block="PostsListing" elem="Grid">
                            { this.renderGrid() }
                            { this.renderLoadMore() }
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default PostsListingComponent;
