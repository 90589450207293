/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Input from 'Component/FieldInput';
import Field from 'SourceComponent/Field/Field.component';

import './Field.override.style.scss';

/** @namespace Viallidesign/Component/Field/Component/FieldComponent */
export class FieldComponent extends Field {
    renderLabel() {
        // eslint-disable-next-line fp/no-let
        let { label } = this.props;
        const {
            id,
            validation
        } = this.props;
        const isRequired = validation.includes('notEmpty');

        if (!label) {
            return null;
        }

        // Ugly hack, for some reason __(label) didn't translate it,
        // even though translation exists and works with the same string when it's passed as literal
        if (label === 'Yes') {
            label = __('Yes');
        }

        if (label === 'No') {
            label = __('No');
        }

        return (
            <label
              block="Field"
              elem="Label"
              mods={ { isRequired } }
              htmlFor={ id }
            >
                { __(label) }
            </label>
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            handleChange,
            value
        } = this.props;

        return (
            <>
                <button
                  disabled={ +value === min }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                >
                    <span>–</span>
                </button>
                <Input
                  { ...this.props }
                  type="number"
                  readOnly
                    // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ this.onKeyEnterDown }
                  value={ value }
                />
                <button
                  disabled={ +value === max }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                >
                    <span>+</span>
                </button>
            </>
        );
    }

    render() {
        const {
            mix,
            type,
            message,
            validationStatus,
            id
        } = this.props;

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message,
                  isValid: validationStatus === true,
                  [id]: true
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

export default FieldComponent;
