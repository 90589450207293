/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import RecentPosts from '../component/BlogRecentPosts';

export const RECENT_POSTS = 'RecentPosts';

export class WidgetFactoryComponentPlugin {
    aroundRenderMap(args) {
        return {
            ...args,
            [RECENT_POSTS]: {
                component: RecentPosts
            }
        };
    }
}

const { aroundRenderMap } = new WidgetFactoryComponentPlugin();

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: {
                position: 100,
                implementation: aroundRenderMap
            }
        }
    }
};
