/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React from 'react';

export class CheckoutSuccessPlugin {
    aroundRender(args, callback, instance) {
        const { orderID, isError, totals, email, shippingAddress } = instance.props;

        if (isError) {
            return (
                <div block="CheckoutSuccess">
                    <h3>{ __('Your order # is: %s', orderID) }</h3>
                    <p>{ __('We`ll email you an order confirmation with details and tracking info.') }</p>
                    { /* eslint-disable-next-line max-len */ }
                    <p>{ __('There was an error during your payment process. Please contact support for additional information regarding this matter.') }</p>
                    { instance.renderButtons() }
                </div>
            );
        }

        return callback(...args);
    }
}

const { aroundRender } = new CheckoutSuccessPlugin();

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render: {
                position: 100,
                implementation: aroundRender
            }
        }
    }
};
