/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { SCROLL_DEBOUNCE_DELAY } from '@scandipwa/scandipwa/src/component/Menu/Menu.config';

import Menu from 'SourceComponent/Menu/Menu.component';
import { debounce } from 'Util/Request';

import './Menu.override.style';

/** @namespace Viallidesign/Component/Menu/Component/MenuComponent */
export class MenuComponent extends Menu {
    renderPromotionCms() {
        return null;
    }

    isVialliDesignTheme() {
        return document.body.classList.contains('viallidesign');
    }

    renderCurrencySwitcher() {
        return null;
    }

    renderStoreSwitcher() {
        return null;
    }

    componentDidMount() {
        const { closeMenu } = this.props;

        // eslint-disable-next-line no-undef
        this.debouncedCloseMenu = debounce(closeMenu, SCROLL_DEBOUNCE_DELAY);

        window.addEventListener('scroll', this.debouncedCloseMenu);

        document.body.setAttribute('data-page', 'menu');
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedCloseMenu);

        document.body.setAttribute('data-page', '');
    }

    render() {
        const { closeMenu } = this.props;

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >
                { this.renderTopLevel() }
            </div>
        );
    }

    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;
        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderComparePageLink() }
                { this.renderPromotionCms() }
            </>
        );
    }
}

export default MenuComponent;
