/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CheckoutSuccess from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';


import PropTypes from 'prop-types';
import SovendusBanner from 'sovendus-voucher-network-and-checkout-benefits-react';
import Link from 'Component/Link';

import './CheckoutSuccess.override.style.scss';
import {TotalsType} from "Type/MiniCart";
import {addressType} from "Type/Account";
import React, { useState } from "react";

/** @namespace Viallidesign/Component/CheckoutSuccess/Component/CheckoutSuccessComponent */
export class CheckoutSuccessComponent extends CheckoutSuccess {
    static propTypes = {
        orderID: PropTypes.string.isRequired,
        totals: TotalsType.isRequired,
        shippingAddress: addressType.isRequired,
        email: PropTypes.string
    };

    renderButtons() {
        return (
            <div block="CheckoutSuccess" elem="ButtonWrapper">
                <Link
                  block="Button"
                  mix={ { block: 'CheckoutSuccess', elem: 'ContinueButton' } }
                  to="/"
                >
                    { __('Continue shopping') }
                </Link>
            </div>
        );
    }

    render() {
        const { orderID, email } = this.props;
        const { totals: { grand_total, quote_currency_code, coupon_code } } = this.props;
        const { shippingAddress: { firstname, lastname, postcode, country_id, city, street, telephone } } = this.props;


        const streetName = street[0];
        const streetNumber = (typeof street[2] !== "undefined") ? street[1] + ' / ' + street[2] : street[1];

        const trafficSourceNumber = 7744;
        const trafficMediumNumber = 1;
        const yearOfBirth = 1990;
        const sessionId = orderID;


        const sovId = "sovendus-container-" + orderID;

        window.sovIframes = window.sovIframes || [];
        window.sovIframes.push({
            trafficSourceNumber: trafficSourceNumber,
            trafficMediumNumber: trafficMediumNumber,
            sessionId: orderID + grand_total,
            timestamp: Math.floor(Date.now() / 1000),
            orderId: orderID,
            orderValue: grand_total,
            orderCurrency: quote_currency_code,
            usedCouponCode: coupon_code,
            iframeContainerId: sovId,
            integrationType: "mepalpolska-1",
        });

        window.sovConsumer = {
            consumerSalutation: "",
            consumerFirstName: firstname,
            consumerLastName: lastname,
            consumerEmail: email,
            consumerCountry: country_id,
            consumerZipcode: postcode,
            consumerCity: city,
            consumerPhone: telephone,
            consumerYearOfBirth: yearOfBirth,
            consumerStreet: streetName,
            consumerStreetNumber: streetNumber
        };

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
            window.location.protocol +
            "//api.sovendus.com/sovabo/common/js/flexibleIframe.js";
        document.body.appendChild(script);




        // console.log(trafficSourceNumber);
        // console.log(trafficMediumNumber);
        // console.log(orderID);
        // console.log(email);
        // console.log(grand_total);
        // console.log(quote_currency_code);
        // console.log(coupon_code);
        // console.log(firstname);
        // console.log(lastname);
        // console.log(postcode);
        // console.log(country_id);
        // console.log(city);
        // console.log(street);
        // console.log(telephone);
        // console.log(streetName);
        // console.log(streetNumber);


        return (
            <div block="CheckoutSuccess">
                <h3>{__('Your order # is: %s', orderID)}</h3>
                <p>{__('We`ll email you an order confirmation with details and tracking info.')}</p>
                {this.renderButtons()}
                <div id={sovId}></div>
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
