/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import { convertDateFormat } from '../../util/Date';
import { getPostUrl } from '../../util/Posts';
import { MAX_RECENT_POSTS } from './BlogRecentPosts.config';

import './BlogRecentPosts.style';

/** @namespace Blog/Component/BlogRecentPosts/Component/BlogRecentPostsComponent */
export class BlogRecentPostsComponent extends PureComponent {
    static propTypes = {
        posts: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        title: PropTypes.string,
        extraClass: PropTypes.string
    };

    static defaultProps = {
        title: __('Recent Posts'),
        extraClass: ''
    };

    renderContents() {
        const {
            posts,
            isLoaded
        } = this.props;
        const post = {};

        if (!isLoaded) {
            return Array(MAX_RECENT_POSTS)
                .fill()
                .map((_, i) => this.renderCard(post, i));
        }

        return posts.map(this.renderCard);
    }

    renderCard(post, key) {
        const {
            title,
            first_image,
            short_content,
            publish_time
        } = post || {};

        const postUrl = getPostUrl(post);
        const currentPost = decodeURI(window.location.pathname) === postUrl ? 'CurrentPost' : '';
        const name = 'mepalpolska.pl';

        return (
            <li
              block="BlogRecentPosts"
              elem={ `PostCard ${ currentPost }` }
              key={ key }
            >
                <Link
                  rel="nofollow"
                  to={ postUrl }
                  title={ __('View %s', title) }
                  mix={ {
                      block: 'BlogRecentPosts',
                      elem: 'ImageWrapper'
                  } }
                >
                    <Image
                      src={ first_image }
                      ratio="4x3"
                      isPlaceholder={ !first_image }
                    />
                </Link>
                <div
                  block="BlogRecentPosts"
                  elem="Wrapper"
                >
                    <div block="BlogRecentPosts" elem="Extra-Information">
                        <Link
                          rel="nofollow"
                          block="BlogPostCard"
                          elem="Author"
                          to={ postUrl }
                        >
                            { __('Author: %s', name) }
                        </Link>

                        <Link
                          rel="nofollow"
                          block="BlogPostCard"
                          elem="PublishDate"
                          to={ postUrl }
                        >
                            <TextPlaceholder
                              mix={ {
                                  block: 'BlogRecentPosts',
                                  elem: 'PostDatePlaceholder'
                              } }
                              content={ convertDateFormat(publish_time) }
                              length="short"
                            />
                        </Link>

                    </div>

                    <Link
                      block="BlogRecentPosts"
                      elem="PostTitle"
                      to={ postUrl }
                    >
                        <h3 block="BlogRecentPosts" elem="PostTitle">
                            <TextPlaceholder
                              mix={ {
                                  block: 'BlogRecentPosts',
                                  elem: 'PostTitlePlaceholder'
                              } }
                              content={ title }
                              length="short"
                            />
                        </h3>

                    </Link>

                    <div block="BlogRecentPosts" elem="ShortContent">
                        <div dangerouslySetInnerHTML={ { __html: short_content } } />
                    </div>

                    <Link
                      rel="nofollow"
                      block="BlogRecentPosts"
                      elem="ReadMore"
                      to={ postUrl }
                    >
                        { __('Read more') }
                    </Link>
                </div>
            </li>
        );
    }

    render() {
        const {
            title,
            extraClass
        } = this.props;

        return (
            <div block="BlogRecentPosts" elem="Wrapper">
                <h3 block="BlogRecentPosts" elem="Title">
                    { title }
                </h3>
                <ul block="BlogRecentPosts" elem={ `List ${ extraClass }` }>
                    { this.renderContents() }
                </ul>
            </div>
        );
    }
}

export default BlogRecentPostsComponent;
