/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React, { PureComponent } from 'react';

import ProductLinks from 'Component/ProductLinks';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import './BlogRelatedProducts.style';

/** @namespace Blog/Component/BlogRelatedProducts/Component/BlogRelatedProductsComponent */
export class BlogRelatedProductsComponent extends PureComponent {
    render() {
        return (
            <div block="BlogRelatedProducts" elem="Wrapper">
                <ProductLinks
                  linkType={ RELATED }
                  title={ __('Featured products') }
                />
            </div>
        );
    }
}

export default BlogRelatedProductsComponent;
