/* eslint-disable no-magic-numbers */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductAttributeValue from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style.scss';

/** @namespace Viallidesign/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends ProductAttributeValue {
    renderYearsValue(attribute_value) {
        const attributeOption = this.getOptionLabel(attribute_value);
        const {
            label
        } = attributeOption;

        const yearsTranslation = (label * 1) < 2 ? __('year') : __('years');
        return this.renderStringValue(`${ (label * 1).toString() } ${ yearsTranslation }`);
    }

    renderCmValue(value, label) {
        return this.renderStringValue(`${ (value * 1).toString() } cm`, label);
    }

    renderMlValue(value, label) {
        return this.renderStringValue(`${ (value * 1).toString() } ml`, label);
    }

    renderAttributeByType() {
        const {
            attribute: {
                attribute_type,
                attribute_value,
                attribute_code
            }
        } = this.props;

        // eslint-disable-next-line default-case
        switch (attribute_code) {
        case 'length':
        case 'width':
        case 'height':
        case 'thickness':
        case 'diameter':
        case 'package_height':
        case 'package_length':
        case 'package_width':
            return this.renderCmValue(attribute_value);
        case 'capacity':
            return this.renderMlValue(attribute_value);
        case 'warranty':
            return this.renderYearsValue(attribute_value);
        }

        switch (attribute_type) {
        case 'select':
            return this.renderSelectAttribute();
        case 'boolean':
            return this.renderBooleanAttribute();
        case 'text':
            return this.renderTextAttribute();
        case 'multiselect':
            return this.renderMultiSelectAttribute();
        case 'media_image':
            return this.renderImageAttribute();
        case 'textarea':
            return this.renderTextAreaAttribute();
        default:
            return this.renderPlaceholder();
        }
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: {
                attribute_code,
                attribute_value
            },
            mix,
            isFormattedAsText
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        // eslint-disable-next-line fp/no-let
        let order = 0;

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (attribute_code === 'capacity_list') {
            const { attribute_options } = attribute;
            const { label } = attribute_options[attribute_value];
            const replacedValue = label.replace('ml', '');

            order = Number.isInteger(Number(replacedValue)) ? Number(replacedValue) : order;
        }

        return (
            <a
              style={ { order } }
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValueComponent;
